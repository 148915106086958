import React from "react"
import { Link } from "gatsby"

import "../styles/header.css"

export default () => (
  <div id="links-container">
    <Link className="link" to="/about" activeClassName="activeLink">
      About Me
    </Link>
    <Link className="link" to="/projects" activeClassName="activeLink">
      Projects
    </Link>
    <Link className="link" to="/contact" activeClassName="activeLink">
      Contact Me
    </Link>
  </div>
)
